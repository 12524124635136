import React from "react";

function About(){
    return(
        <div>
            <h1>
              This is About Section!
            </h1>
        </div>
    )
}

export default About;